import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import Meta from '../components/Meta';

const Error_page = () => {
  return (
    <div>
      <Meta title='404 || Bit5 | Create. Collect. Connect.' />
      <div className=''>
        {/* <!-- 404 --> */}
        <section className='relative py-16 dark:bg-jacarta-800 md:py-24'>
          <picture className='pointer-events-none absolute inset-0 -z-10 dark:hidden'>
            <Image src='/images/gradient_light.jpg' alt='gradient' width={1920} height={789} />
          </picture>

          <div className='container'>
            <div className='mx-auto max-w-lg text-center'>
              <span className='mb-14 inline-block'>
                {/* <Image src='/images/404.png' width={336} height={165} alt='gradient' /> */}
                <div className='block h-[165px] w-[336px] bg-[url(/images/404-light.svg)] bg-contain bg-no-repeat dark:bg-[url(/images/404-dark.svg)]' />
              </span>
              <h1 className='mb-6 font-display text-4xl text-jacarta-700 dark:text-white md:text-6xl'>
                Page Not Found!
              </h1>
              <p className='text-lg leading-normal dark:text-jacarta-300'>
                Oops! The page you are looking for does not exist.
              </p>
              <p className='mb-12 text-lg leading-normal dark:text-jacarta-300'>
                It might have been moved or deleted.
              </p>
              <Link href='/'>
                <a className='rounded-xl inline-block bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'>
                  Navigate Back Home
                </a>
              </Link>
            </div>
          </div>
        </section>
        {/* <!-- end 404 --> */}
      </div>
    </div>
  );
};

export default Error_page;
